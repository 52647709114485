import PageHeader from '@/components/PageHeader'
import tableMixin from '@/minxin/table-minxin'
import Adsearch from '@/components/Adsearch'
const Index = {
  name: 'InventoryTransfer',
  mixins: [tableMixin],
  components: {
    PageHeader,
    Adsearch
  },
  data() {
    return {
      visible: false,
      formSearch: {
        KeyWords: '',
        type: 2, //   1 ： 按调拨单 2 ： 按物料
        InventorID: '', // 调拨单号
        InStockCode: '',
        OutStockCode: '',
        OUTStockName: '', // 调出仓库
        INStockName: '', // 调入仓库
        BeginTime: '',
        EndTime: '',
        InvCode: '',
        InvName: '',
        DINum: '',
        writeTime: [],
        PageIndex: 1,
        PageSize: 20
      },
      isShow: false,
      adSearchForm: [
        { name: '调拨单号', value: 'InventorID' },
        { name: '调出仓库', value: 'OUTStockName' },
        { name: '调入仓库', value: 'INStockName' },
        { name: '调拨时间段', value: 'writeTime', date: true }
      ],
      isSelectArr: [],
      print_info: { lst: [] }
    }
  },
  methods: {
    isChnageP(e) {
      this.isSelectArr = e
    },
    searchAd() {
      console.log(this.formSearch)
      if (this.formSearch.writeTime.length > 0) {
        this.formSearch.BeginTime = this.formSearch.writeTime[0]
        this.formSearch.EndTime = this.formSearch.writeTime[1]
      }
      this.setKeysData(this.formSearch, this.adSearchForm) // 展示搜索条件
      this.getTableDataAsync()
      this.isShow = false
    },
    selectType(type) {
      this.formSearch.type = type
      this.visible = false
      this.getTableDataAsync()
      this.$nextTick(() => { // 在数据加载完，重新渲染表格
        this.$refs.multipleTable.doLayout()
      })
    },
    toDetail(row) {
      if (row.Status === 2) {
        // 已完成
        this.$router.push({
          name: 'InventoryTransferDetail',
          params: { IDX: row.InventorIDX }
        })
      }
      if (row.Status === 1) {
        // 未完成
        this.$router.push({
          name: 'newInventory',
          params: { IDX: row.InventorIDX }
        })
      }
    },
    // 导出
    export_() {
      const obj = {
        type: this.formSearch.type, // 1：按调拨单 2：按物料
        CheckedIdxs: '', // 选中条数
        InventorID: this.formSearch.InventorID,
        InStockCode: this.formSearch.INStockName,
        OutStockCode: this.formSearch.OUTStockName,
        InvCode: this.formSearch.InvCode,
        InvName: this.formSearch.InvName,
        DINum: ''
      }
      this.isSelectArr.map((item, index) => {
        if (index !== this.isSelectArr.length - 1) return obj.CheckedIdxs += item.IDX + ','
        return obj.CheckedIdxs += item.IDX
      })
      this.$refs.ExportLoading.open()
      this.$api.InventoryTransfer_Export(obj).then(res => {
        if (res.RetCode !== '0') return this.$message.error('错误：' + res.RetMsg)
        const name = this.$minCommon.getExportName('库存调拨')
        this.$refs.ExportLoading.success(res.Message, name)
        return this.$minCommon.downloadFile(res.Message, name)
      })
    },
    async getTableDataAsync(page, pageSize) {
      this.formSearch.PageIndex = page || 1
      this.formSearch.PageSize = this.tablePageSize
      // delete this.formSearch.writeTime
      const response = await this.$api.GetInventoryTransferInfoList(this.formSearch)
      console.log(response)
      this.tableData = response.Message
      this.tableTotalSize = response.Total
      return { Message: response.Message, Total: response.Total }
    },
    // 获取明细
    getDetail(IDX) {
      this.$api.GetInventoryTransferInfo({ IDX }).then(res => {
        if (res.RetCode !== '0') return this.$message.error('错误：' + res.RetMsg)
        this.print_info = res.Message
        this.print_() // 打印
      })
    },
    closeOrder(IDX) {
      this.$api.InventoryTransferInfo_Close({ IDX }).then(res => {
        console.log(res)
        if (res.RetCode !== '0') return this.$message.error('错误：' + res.RetMsg)
        this.$message.success('关闭成功')
        this.getTableDataAsync()
      }).catch(err => {
        return this.$message.error('错误：' + err)
      })
    },
    group(array, subGroupLength) {
      let index = 0
      const newArray = []
      while (index < array.length) {
        newArray.push(array.slice(index, index += subGroupLength))
      }
      return newArray
    },
    // 打印调拨单
    print_(row) {
      // this.getDetail(row.IDX)
      const top_view = ` <div style='padding: 20px 0' class="flex j-between a-center p-tb-20 top_view_html">
      <div>调出仓库：${this.print_info.INStockName ? this.print_info.INStockName : ''}</div>
      <div>调入仓库： ${this.print_info.OUTStockName ? this.print_info.OUTStockName : ''}</div>
      <div>操作人： ${this.print_info.CreateUserName ? this.print_info.CreateUserName : ''}</div>
      <div>单据备注： ${this.print_info.Remark ? this.print_info.Remark : ''}</div>
      <div>调出时间： ${this.print_info.WriteTime ? this.print_info.WriteTime : ''}</div>
    </div>`

      const label = [
        { label: '物料编号', prop: 'InvCode' },
        { label: '物料名称', prop: 'InvName' },
        { label: '品牌', prop: 'BrandName' },
        { label: '物料类型', prop: 'MaterialTypeName' },
        { label: '规格', prop: 'Specification' },
        { label: '单位', prop: 'StockUnitN' },
        { label: '批号/序列号', prop: 'LotNum' },
        { label: '入库批次', prop: 'LotNo' },
        { label: '效期', prop: 'ExpiredDate' },
        { label: '可调拨数量', prop: 'StockNum' },
        { label: '调拨数量', prop: 'InventoryNum' },
        { label: '状态', prop: 'strStatus' },
        { label: '调出时间', prop: 'WriteTime' },
        { label: '操作人', prop: 'CreateUserName' },
        { label: 'UDI码', prop: 'DINum' },
        { label: '贮存条件', prop: 'StorageConditionName' }
      ]
      let str_tr = ''
      let str_td = ''
      let num = 0
      label.map(item => {
        num += 1
        str_tr += `
              <th style="font-size: 12px;font-weight:400;min-width:100px" >${item.label}</th>
          `
      })
      let nums = 0
      this.print_info.lst.map((item, index) => {
        nums += item.InventoryNum * 1
        label.map((item1, index1) => {
          str_td += `<td  id="testTd" style="text-align:center;border:solid #e5e5e5 1px;font-size: 12px">${item[item1.prop] ? item[item1.prop] : ''}</td>]`
        })
      })

      const testTD = str_td.split(']')
      testTD.splice(str_td.split(']').length - 1, 1)
      let arr = []
      let str = ''
      arr = this.group(testTD, num)
      arr.map((item, index) => {
        str += `<tr>${item.join('')}</tr>`
      })
      const table_ = `
      <table border="1" class='table-0 m-tb-20 ' id="dasasasdsad" cellpadding="0" cellspacing="0" style="border-collapse:collapse;border:none;width:100%">
      <tr>
        ${str_tr}
     </tr>
       ${str}
    </table>
  `
      const heji = `<div style='padding: 20px 0' class="flex j-end a-center m-lr-20 heji">
                    <div>品牌合计：${this.print_info.lst.length}</div>
                    <div class="m-left-20">调拨数量：${nums}</div>
                  </div>`
      const divElements = `<div class='div-0'>${top_view} ${table_} ${heji}  </div>`
      var printWindow = window.open(Math.random() * 1000, '_blank', '')
      printWindow.document.open()
      printWindow.document.write('<html><head><meta http-equiv="Pragma" content="no-cache" /><meta http-equiv="Cache-Control" content="no-cache" /><meta http-equiv="Expires" content="0" /><title style="margin-top:20px">' + '调拨单' + '</title>')
      printWindow.document.write(`<style type="text/css">
       html, body {
         font-size: 12px;
         margin: 0;
       }
      .p-lr-20 {padding: 0 20px; }
      .m-bottom-20 {margin-bottom: 20px; }
      .weight { font-weight: 700 }
      .f14 { font-size:12px}
      .f20 {font-size:12px}
      .flex {display:flex}
      .j-center {justify-content: center;}
      .j-end { justify-content: flex-end; }
      .column { flex-direction: column;}
      .a-center {align-items: center;}
      .f-warp { flex-wrap: wrap; }
      .j-start {justify-content: flex-start;}
      .m-tb-10 {margin: 10px 0;}
      .m-tb-20 {margin: 20px 0;}
      .m-lr-20 {margin: 0 20px; }
      .j-between {justify-content: space-between;}
      .m-top-10 {margin-top: 10px }
       .p-tb-20 { padding: 20px 0}
       .p-lr-20 { padding: 0 20px}
       .m-left-20 {margin-left:20px}
      .element.style {width: auto; }
      .table_info {
        max-width: 1000px;
        margin: 0 auto;
      }
      .div0 {
        box-sizing: border-box;
      }
       .div-0 {page-break-after: always}
      table{table-layout: fixed;}
      td{word-break: break-all; word-wrap:break-word;}
      </style>`
      )
      printWindow.document.write('</head><body style="height: 100%">')
      printWindow.document.write(divElements)
      printWindow.document.write('</body></html>')
      printWindow.document.write(`<script type="text/javascript">
      window.onload = function () {
        class Test {
            constructor() {
                this.windowHeight = null // 窗口高度
                this.pageIndex = 0 // 当前页
                this.height_print = '' // 初始高度
                this.max_height = null // 最大高度
                this.tbody_tr = '' // 获取表格子集
                this.splice_arr = [] // 被删掉的元素
                this.header_tr = null // 表头的tr 不改动
                this.table_main = null // 表格 父级
                this.table_footer_html_0 = null // 表格合计内容
                this.t_b_view = null // 头部信息
            }
            init() {
                 this.windowHeight = window.screen.height
                this.max_height =  Math.round(this.windowHeight  / 2.5)
                 console.log('this.max_height ',this.max_height )
                this.t_b_view = document.getElementsByClassName('top_view_html')[0].innerHTML
                // this.table_main = document.getElementById('dasasasdsad') // 表格 父级
                this.table_main = document.getElementsByClassName('table-0')[this.pageIndex] // 表格 父级
                this.tbody_tr = this.table_main.childNodes[1].children // 获取表格子集
                this.header_tr = this.tbody_tr[0].innerHTML // 表头的tr 不改动
                this.table_footer_html_0 = document.getElementsByClassName('heji')[0].innerHTML // 表格合计内容
    
                this.heightA()
                this.isSet()
            }
    
            // 是否换页
            isSet() {
                if (this.height_print <= this.max_height) return // 不需要换页
                if (this.height_print > this.max_height) {
                     this.setData()
                }
            }
            // 处理换页的数据
            setData() {
                // 从header_tr下一个开始循环 this.tbody_tr{
                // for (let i = 1; i < this.splice_arr.length - 1; i++)
                const arr = []
                for (let i = this.tbody_tr.length - 1; i >= 1; i--) {
                    if (this.height_print > this.max_height) {
                        // 将一个节点存起来
                        arr.push(this.tbody_tr[i])
                        // 删除一个节点
                        this.table_main.childNodes[1].removeChild(this.tbody_tr[i])
                        this.heightA()
                    }
                }
                this.tbody_tr = arr
                this.initDocument()
            }
            // 计算高度 当前页高度
            heightA() {
                this.height_print = document.getElementsByClassName('div-0')[this.pageIndex].offsetHeight
                 // console.log('this.height_print', this.height_print)
                // return this.height_print
            }
    
            // 为页面添加新的页
            initDocument() {
                this.pageIndex += 1
                let new_tr_td = ''
                for (let i = 0; i < this.tbody_tr.length; i++) {
                    new_tr_td += '<tr>' + this.tbody_tr[i].innerHTML + '</tr>'
                }
                // 新的表格
                const table_ = "<table border='1' class='table-0' id='dasasasdsad' cellpadding='0' cellspacing='0' style='border-collapse:collapse;border:none;width:100%'>" + this.header_tr + new_tr_td + '</table>'
                const dom_new = "<div class='div-0'>" + "<div style='padding: 20px 0' class='flex j-between a-center p-tb-20 top_view_html'>" + this.t_b_view + '</div>' + table_ + '<div style="padding: 20px 0" class="flex j-end a-center m-lr-20 heji">' + this.table_footer_html_0 + '</div>' + "</div>"
                const conentDiv = document.createElement('div')
                conentDiv.innerHTML = dom_new
                document.body.appendChild(conentDiv)
                this.init()
            }
        }
       
         setTimeout(() => {
              const test = new Test()
              test.init()
        }, 60)
    }
       </script>`)
      printWindow.document.close()
      printWindow.focus()
      setTimeout(function() {
        printWindow.print()
        printWindow.close()
      }, 100)
    }
  }
}

export default Index
