<template>
  <div class="Inventory-transfer">
    <PageHeader title="库存调拨" />
    <div class="searchView  flex a-center m-tb-10 j-between p-lr-20">
      <!--      <div class="flex">-->
      <!--        <el-popover-->
      <!--          v-model="visible"-->
      <!--          class="OrderDropDown"-->
      <!--          placement="bottom"-->
      <!--          trigger="manual"-->
      <!--          :width="80"-->
      <!--          popper-class="test_pop_view"-->
      <!--        >-->
      <!--          <div class="flex column">-->
      <!--            <div class="p-bottom-10 cursor" @click="selectType(1)">按调拨单查询</div>-->
      <!--            <div class="cursor" @click="selectType(2)">按物料查询</div>-->
      <!--          </div>-->
      <!--          <div slot="reference" class="out white bg cursor p-tb-5 min-border-right flex a-center f14 pointer" @click.stop="visible = !visible">-->
      <!--            <div>{{ formSearch.type === 1 ? '按调拨单查询' : '按物料查询' }}</div>-->
      <!--            <i class="el-icon-caret-bottom" />-->
      <!--          </div>-->
      <!--        </el-popover>-->
      <!--      </div>-->
      <div class="out white  bg p-lr-10 p-tb-5 min-border-right flex a-center m-left-10  pointer  ModelBttton-white-30" @click="export_">
        <i class="el-icon-upload2 f15  p-right-10 " />   导出
      </div>
      <!-- <div class="flex" @click="() => $refs.FieldManagement.openSetFieldManagement()">
        <div class="out cursor p-lr-10 p-tb-5 min-border flex a-center f14 m-lr-10">
          <i class="el-icon-setting f15 p-right-5" />   字段管理
        </div>
      </div> -->
      <div class="flex">
        <!-- <div class="out p-lr-10 p-tb-5 min-border flex a-center f12 m-right-10">
          <i class="el-icon-setting f15 p-right-5" />   字段管理
        </div> -->
      </div>
      <div class="flex m-lr-20  SearchBox_30" style="flex:1">
        <el-input v-model="formSearch.KeyWords" size="medium" placeholder="可通过调拨单号、调拨名称、物料号、物料名称、物料DI码搜索" class="rule-input-edit">
          <template slot="prepend">
            <div class="pointer" @click="() => isShow = true">
              <i class="el-icon-s-operation" />
              <span>高级搜索</span>
            </div>
          </template>
          <div slot="append" class="cursor" @click="searchGetTableDataAsync">搜  索</div>
        </el-input>
      </div>
      <el-button size="mini" icon="el-icon-circle-plus-outline" round @click="() => $router.push({name:'newInventory',params:{IDX: 0}})">新增调拨</el-button>
    </div>
    <SearchKeys v-if="isProductCjq" class="m-lr-20" :search-keys="searchKeys" @close="closeTag($event,formSearch,adSearchForm,'writeTime','BeginTime','EndTime')" />
    <!-- 表格 -->
    <div class="p-lr-20">
      <el-table
        v-if="formSearch.type === 1"
        id="testTable"
        ref="multipleTable"
        v-loading="tableLoading"
        height="calc(100vh - 370px)"
        :header-cell-style="headerCStyle"
        :data="tableData"
        tooltip-effect="dark"
        show-header
        row-key="id"
        :border="true"
        @selection-change="isChnageP"
      >
        <el-table-column fixed="left" type="selection" width="40" />
        <el-table-column align="center" prop="InventorID" label="调拨单号" show-overflow-tooltip />
        <el-table-column align="center" prop="OUTStockName" label="调出仓库" show-overflow-tooltip />
        <el-table-column align="center" prop="INStockName" label="调入仓库" show-overflow-tooltip />
        <el-table-column align="center" prop="WriteTime" label="调出时间" show-overflow-tooltip />
        <el-table-column align="center" prop="CreateUserName" label="操作人" show-overflow-tooltip />
        <el-table-column align="center" prop="Remark" label="备注" show-overflow-tooltip />
        <el-table-column align="center" prop="strStatus" label="状态" show-overflow-tooltip />
        <el-table-column width="50" align="center" prop="set" label="操作" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-popover
              placement="left"
              trigger="hover"
              :width="100"
              popper-class="test_pop_view"
            >
              <!-- 已关闭时只有查看 -->
              <div class="DropDownList">
                <div class="OperationButton"> <!-- () => $router.push({name: 'view-order', params: scope.row }) -->
                  <el-link :underline="false" @click="toDetail(scope.row)"><i class="el-icon-view p-right-10" />查看调拨单</el-link>
                </div>
                <!-- 已调拨 -->
                <div v-if="scope.row.Status === 2" class="OperationButton" @click="getDetail(scope.row.IDX)">
                  <el-link :underline="false"><i class="el-icon-printer p-right-10" />打印调拨单</el-link>
                </div>
                <!-- 未调拨 -->
                <div v-if="scope.row.Status === 1" class="OperationButton" @click="closeOrder(scope.row.IDX)">
                  <span><el-link :underline="false"><i class="el-icon-truck p-right-10" />关闭调拨单</el-link></span>
                </div>
              </div>
              <i slot="reference" class="el-icon-more" />
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
      <el-table
        v-if="formSearch.type === 2"
        id="testTable"
        ref="multipleTable"
        v-loading="tableLoading"
        height="calc(100vh - 330px)"
        :header-cell-style="headerCStyle"
        :data="tableData"
        tooltip-effect="dark"
        show-header
        row-key="id"
        :border="true"
        @selection-change="isChnageP"
      >
        <el-table-column fixed="left" type="selection" width="40" />
        <el-table-column align="center" prop="InventorID" label="调拨单号" show-overflow-tooltip />
        <el-table-column align="center" prop="InvCode" label="物料编号" show-overflow-tooltip />
        <el-table-column align="center" prop="InvName" label="物料名称" show-overflow-tooltip />
        <el-table-column align="center" prop="BrandName" label="品牌" show-overflow-tooltip />
        <el-table-column align="center" prop="MaterialTypeName" label="物料类型" show-overflow-tooltip />
        <el-table-column align="center" prop="Specification" label="规格" show-overflow-tooltip />
        <el-table-column align="center" prop="StockUnitN" label="单位" show-overflow-tooltip />
        <el-table-column align="center" prop="LotNum" label="批号/序列号" show-overflow-tooltip />
        <el-table-column align="center" prop="LotNo" label="入库批次" show-overflow-tooltip />
        <el-table-column align="center" prop="ExpiredDate" label="效期" show-overflow-tooltip />
        <el-table-column align="center" prop="InventoryNum" label="调拨数量" show-overflow-tooltip />
        <el-table-column align="center" prop="strStatus" label="状态" show-overflow-tooltip />
        <el-table-column align="center" prop="InventoryTime" label="调出时间" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ $minCommon.formatDate(new Date(scope.row.InventoryTime), 'yyyy-MM-dd hh:mm') }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="InventoryUserName" label="调拨人" show-overflow-tooltip />
        <el-table-column align="center" prop="DINum" label="DI码" show-overflow-tooltip />
        <el-table-column align="center" prop="OUTStockName" label="调出仓库" show-overflow-tooltip />
        <el-table-column align="center" prop="INStockName" label="调入仓库" show-overflow-tooltip />
        <el-table-column align="center" prop="Remark" label="备注" show-overflow-tooltip />
        <el-table-column align="center" prop="" label="操作" fixed="right" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-popover
              placement="left"
              trigger="hover"
              :width="100"
              popper-class="test_pop_view"
            >
              <!-- 已关闭时只有查看 -->
              <div class="DropDownList">
                <div class="OperationButton" @click="toDetail(scope.row)"> <!-- () => $router.push({name: 'view-order', params: scope.row }) -->
                  <el-link :underline="false"><i class="el-icon-view p-right-10" />查看调拨单</el-link>
                </div>
                <!-- 已调拨 -->
                <div v-if="scope.row.Status === 2" class="OperationButton" @click="print_(scope.row)">
                  <el-link :underline="false"><i class="el-icon-printer p-right-10" />打印调拨单</el-link>
                </div>
                <!-- 未调拨 -->
                <div v-if="scope.row.Status === 1" class="OperationButton" @click="closeOrder(scope.row.IDX)">
                  <span><el-link :underline="false"><i class="el-icon-truck p-right-10" />关闭调拨单</el-link></span>
                </div>
              </div>
              <i slot="reference" class="el-icon-more" />
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-container flex j-between">
        <el-pagination
          :ref="paginationName"
          :total="tableTotalSize"
          :pager-count="pagerCount"
          :page-size="tablePageSize"
          :background="tableBackground"
          :current-page.sync="currentPage"
          layout="prev, pager, next, jumper"
          @current-change="handleCurrentChange($event)"
        />
        <!-- <el-button type="primary" icon="el-icon-printer" @click="print">打印</el-button> -->
      </div>
    </div>
    <Adsearch :form="formSearch" :ad-search-form="adSearchForm" :show="isShow" @cancel="(e) => isShow = e" @searchAd="searchAd" />
    <!-- 导出 -->
    <ExportLoading ref="ExportLoading" />
  </div>
</template>
<script>
import Index from '@/minxin/management/Inventory-transfer'
export default Index
</script>
<style lang="scss" scoped>
@import '@/style/search.scss';

</style>
